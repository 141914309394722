.nav_wrap {
    width: 307px;
    margin: 0;
    padding: 45px 0 0 0;
    float: left;
    .left-nav {
        font-size: 15px;
        line-height: 20px;
        width: 252px;
        ul {
            margin-left: 0;
            list-style-type: none;
            list-style-position: outside;
            li {
                position: relative;
                padding: 20px 0 20px 45px;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 40px;
                    height: 1px;
                    background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(0,0,0,1) 0%, rgba(40,40,48,1) 80%, rgba(51,51,51,0) 100%, rgba(0,212,255,0) 100%);
                }

                a {
                    color: #636466;
                    text-decoration: none;
                }
            }
        }
    }
}

// Navi active
#page_index .nav_wrap ul li:hover,
#page_index .nav_wrap ul li:nth-child(1),
#page_management .nav_wrap ul li:nth-child(2),
#page_leistungen .nav_wrap ul li:nth-child(3),
#page_kontakt .nav_wrap ul li:nth-child(4) {
    a {
        color: #da7435;
        text-decoration: none;
        font-weight: bold;
        line-height: 18px;
    }
}

.mobile-navi {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    &__toggler {
        height:$header_height_mobile;
        width: 40px;
        min-width: 40px;
        padding-top:$header_height_mobile / 2 - 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;

        > span {
            display: block;
            width: 80%;
            height: 3px;
            border-radius: 3px;
            background-color: $primary_color;
            margin: 3px 0;
            transition: .3s;
        }
    }

    &__menu {
        display: none;
        position: fixed;
        top: $header_height_mobile;
        right: 15px;
        left: 15px;

        ul {
            li {
                padding: 10px 20px;
                background-color: #FFF;

                + li {
                    border-top: 1px solid #333;
                }
            }
        }
    }

    &--active {
        .mobile-navi__toggler {
            > span {
                &:nth-child(1) {
                    transform: translateY(6px) rotate(45deg);
                }

                &:nth-child(2) {
                    transform: scaleX(0);
                }

                &:nth-child(3) {
                    transform: translateY(-6px) rotate(-45deg);
                }
            }
        }
        .mobile-navi__menu {
            display: block;
        }
    }
}


@media (max-width: 760px) {
    .nav_wrap {
        display: none;
    }
    .mobile-navi {
        display: block;
    }
}


@media (min-width: 761px) and (max-width: 1060px) {
    .nav_wrap {
        width: 175px;
        .left-nav {
            width: 175px;
            ul {
                li {
                    padding-left: 20px;
                }
            }
        }
    }
}