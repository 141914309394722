html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    color: #333;
    font-size: 15px;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 20px;
    padding-right: 30px;
    padding-left: 30px;
}

h1, h2 {
    font-size: $font_size;
    font-weight: bold;
    line-height: 1.5em;
}

h1 {
    color: #62666a;
}

h2 {
    color: $primary_color;
    margin-top: 20px;
}

p {
    margin-top: 20px;
    font-size: $font_size;
    line-height: 1.6em;
}

a {
    text-decoration: none;
    color: $primary_color_light;
    &:hover {
        text-decoration: underline;
    }
}

table {
    tbody {
        tr {
            td {
                font-size: $font_size;
                vertical-align: top;
                padding-top: 20px;
                &:first-child {
                    width: 181px;
                }
            }
        }
    }
}


.quotes-in {
    margin: 5px 5px 0 0;
    float: left;
}

.quotes-out {
    margin: 5px 0 0 5px;
}

.container {
    max-width: 1005px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);
    min-height: 100vh;
    color: $font_color;
}

.bg_wrap {
    background: url(/images/bg-content-area.png) no-repeat right bottom;
    padding-bottom: 30px;
}

.content_wrap {
    max-width: 693px;
    width: 100%;
    float: right;
    padding-top: 25px;
    .content_wrap__text {
        max-width: 590px;
        width: 100%;
        padding: 40px 0 0 0;
    }
}

// Helper classes

.justify {
    text-align: justify;
}

.mt_small {
    margin-top: 5px;
}

.mt_medium {
    margin-top: 20px;
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}


// mobile general

@media (max-width: 760px) {
    body {
        padding-left: 15px;
        padding-right: 15px;
        h1, h2, p {
            font-size: $font_size_mobile;
        }
        table {
            tbody {
                tr {
                    td {
                        font-size: $font_size_mobile;
                    }
                }
            }
        }
    }
    .content_wrap {
        padding: 5%;
        float: none;
        .content_wrap__text {
            padding-top: 15px;
        }
    }
}

@media (min-width: 761px) and (max-width: 1060px) {
    .content_wrap {
        width: 75%;
        .content_wrap__text {
            width: 95%;
        }
    }
}

// Fix iPhone 5

@media (max-width: 385px) {
    table {
        tbody {
            tr {
                td {
                    font-size: 3.7vw!important;
                }
            }
        }
    }
}