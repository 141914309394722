.subnavi {
    display: none;
}

@media (max-width: 760px) {
    .subnavi {
        display: block;
        ul {
            li {
                text-align: center;
                border-top: 1px solid #333;
                padding: 10px 0;
            }
        }
    }
}


@media (min-width: 761px) and (max-width: 1060px) {
    .subnavi {
        display: none;
    }
}