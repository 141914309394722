#footer {
    max-width: 1005px;
    height: 80px;
    margin: 0;
    padding-top: 10px;
    position: relative;
    a {
        text-decoration: underline;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 100px;
        right: 100px;
        height: 1px;
        background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(28,28,44,1) 20%, rgba(40,40,48,1) 80%, rgba(51,51,51,0) 100%, rgba(0,212,255,0) 100%);
    }
}


.footer-nav {
    font-size: 11px;
    height: 30px;
    margin: 10px 20px 0 0;
    text-align: center;
    a {
        color: #4B4B4B;
        text-decoration: none!important;
        &:hover {
            text-decoration: underline;
            color: $primary_color_light;
        }
        &.active {
            color: $primary_color;
            text-decoration: none;
        }
    }
}


@media (max-width: 767px) {
    #footer {
        padding: 4% 1%;
        height: auto;
        &:before {
            left: 8%;
            right: 8%;
        }
        .footer-nav {
            margin: 0;
            height: auto;
        }
    }
}