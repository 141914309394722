#header {
    max-width: 1005px;
    height: 205px;
    margin: 0;
    padding-top: 50px;
    z-index: 2;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 100px;
        right: 100px;
        height: 1px;
        background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(28,28,44,1) 20%, rgba(40,40,48,1) 80%, rgba(51,51,51,0) 100%, rgba(0,212,255,0) 100%);
    }
    .logo {
        width: 392px;
        height: 101px;
        margin: auto;
    }
}



@media (max-width: 767px) {
    #header {
        max-width: 100%;
        height: $header_height_mobile;
        padding: 0;
        &:before {
            left: 8%;
            right: 8%;
        }
        .logo {
            width: auto;
            height: 60px;
            padding: 3% 0 0 3%;
            img {
                width: auto;
                height: 60px;
            }
        }
    }
    
}